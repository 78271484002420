import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SpinnerCircularSplitComponent } from './spinner-circular-split.component';

@NgModule({
  imports: [BrowserModule],
  declarations: [SpinnerCircularSplitComponent],
  exports: [SpinnerCircularSplitComponent],
})
export class SpinnerCircularSplitModule {}
