import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SpinnerRoundOutlinedComponent } from './spinner-round-outlined.component';

@NgModule({
  imports: [BrowserModule],
  declarations: [SpinnerRoundOutlinedComponent],
  exports: [SpinnerRoundOutlinedComponent],
})
export class SpinnerRoundOutlinedModule {}
